body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, grey 1px, transparent 1px), linear-gradient(to top, grey 1px, transparent 1px);
  background-size: 96px 32px;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





.dd-content {
  position: relative;
  z-index: 1;
}




* {
  margin: 0;
  text-decoration-line: none;
  padding-inline-start : 0;


}
.App {
  font-family: sans-serif;
  text-align: center;
}



.loginBackground {
  align-items: center;
  justify-content: center;
  display: flex;
}

.loginleft {

  width: 50vw;

  clip-path: polygon(0 0, 100% 0, 90% 83%, 0% 90%);
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  align-items: center;
}
.loginleft > h1 {
  color: white;
}
.loginleft > p {
  color: white;
  margin: 3px;
}
.loginleft > button {
  width: 150px;
  border: white solid 2px;
  padding: 10px 30px;
  background: transparent;
  border-radius: 20px;
  color: white;
  margin: 20px 0px;
  transition: 0.5s ease-in-out;
  font-weight: bolder;
  outline: none;
}
.loginleft > button:hover {
  background: white;
  color: rgb(0, 110, 255);
  transition: 0.5s ease-in-out;
}
.loginsub {


  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 2vh;

}
.loginsub > p {
  margin: 10px;
  font-weight: bold;
}

.loginsub > a {
  margin: 10px;
  text-decoration-line: underline;
  text-decoration-thickness: 5px;
  text-decoration-color: red;
  font-weight: bold;
}

.loginsub > h1 {
  margin: 10px;
}
.loginsub > input {
  width: 20vw;
  border: none;
  background-color: lightgray;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  outline: none;
}



.loginsub > button {
  border: none;
  padding: 12px 50px;
  background-color: rgb(0, 110, 255);
  font-size: 15px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
  outline: none;
  transition: 0.5s ease-in-out;
}

.loginsub > button:hover {
  box-shadow: 0px 0px 5px 1px black;
  transition: 0.5s ease-in-out;
}
.loginsub > button::after {
  background: black;
  transition: 2s ease-in-out;
}
.loginsub > button:active:after {
  transition: 0s;
}
.loginsub > p {
  margin: 10px;
  font-weight: bold;
}

.loginsub > d {
  margin: 10px;
  color: red;
  font-weight: bold;
}
.loginsub img {
  margin: 0px 10px;
}


.subtitle > h2 {
  margin-top: 2px;
}


.loginright {

  
  width: 50vw;
  display: flex;
  flex-direction: column;
  width: 40vw;
  align-items: center;
  margin-top: 20vh;
  background-color: transparent;

}
.loginright > h1 {
  margin: 10px;
  
}
.loginright > input {
  width: 20vw;
  border: none;
  background-color: lightgray;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  outline: none;
}

.loginright > button {
  border: none;
  padding: 12px 50px;
  background-color: rgb(0, 110, 255);
  font-size: 15px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
  outline: none;
  transition: 0.5s ease-in-out;
}

.loginright > button:hover {
  box-shadow: 0px 0px 5px 1px black;
  transition: 0.5s ease-in-out;
}
.loginright > button::after {
  background: black;
  transition: 2s ease-in-out;
}
.loginright > button:active:after {
  transition: 0s;
}
.loginright > p {
  margin: 10px;
  font-weight: bold;
}
.loginright img {
  margin: 0px 10px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;

}



header {
  width: 100%;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding-top: 10px;
  margin: 0px;

}

.nav-button {
  font-size: 10px;
  color: black;
  font-weight: 0;
  margin-left: 5px;
  padding: 0 2px;
  transition: opacity 0.5s;
}

.nav-link {
  font-size: 14px;
  color: black;
  font-weight: 0;
  padding: 0 5px;
  transition: opacity 0.5s;
}

.nav-link:hover {
  opacity: 0.7;
}

/*.hero {
  height: 624px;
  width: 100%;
  background: #161617;
  color: #f5f5f7;
}*/



@media (max-width: 480px) {
  .loginBackground {
  }
  .loginleft {
    display: none;
  }

  .loginright {
    width: 100vw;
    margin-top: 0px;
    padding-top: 30px;


  }
  .loginright > input {
    width: 50vw;
    background-color: lightgray;
  }
  .loginright > button {
    background-color: rgb(0, 110, 255);
    color: white;
  }


  .loginright > h1 {
    margin: 10px;
  }

  .loginsub {
    width: 100vw;
    margin-top: 0px;
    padding-top: 30px;
  

  }
  .loginsub > input {
    width: 50vw;
    background-color: lightgray;
  }
  .loginsub > button {
    background-color: rgb(0, 110, 255);
    color: white;
  }


  .loginsub > h1 {
    margin: 10px;
  }

  

  

}


@media (max-width: 300px) {

  header {
    width: 100%;
  }
  
  .nav-list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-top: 10px;
    margin: 0px;
  
  }
  
  .nav-button {
    font-size: 10px;
    color: black;
    font-weight: 0;
    margin-left: 5px;
    padding: 0 2px;
    transition: opacity 0.5s;
  }
  
  .nav-link {
    font-size: 11px;
    color: black;
    font-weight: 0;
    padding: 0 5px;
    transition: opacity 0.5s;
  }
  
  .nav-link:hover {
    opacity: 0.7;
  }

  .subtitle > h5 {
    font-size: 10px;
  }
  
  

  

}


